import VerifyModal from './verify-modal';

export function verifyHandler() {
  const taskBtn = document.querySelector('.js-show-verify-btn');
  taskBtn && taskBtn.addEventListener('ajax:success', showVerifyModal);

  function showVerifyModal() {
    const modal = document.querySelector('.js-modal-verify');
    const newModal = new VerifyModal(modal);
    newModal.open();
  }
}