import {getActionBySelect} from '../helpers/selectAction';
import {addFileOption} from '../helpers/uploadIntoSelect';
export default class VoicemailForm {
  constructor(el) {
    this.el = el;
    this.actionSelect = this.el.querySelector('.js-action-select');
    this.actionGroups = this.el.querySelectorAll('.js-action-group');
    this.actionContainer = this.el.querySelector('.js-action-container')

    getActionBySelect(this.actionSelect, this.actionGroups, this.actionContainer);
  }
}