import { Modal } from '../helpers/modal';

export default class VerifyModal extends Modal {
  constructor(el) {
    super(el);
    this.codeInput = el.querySelector('.js-code-input');

    this.codeInput.addEventListener('input', this.validateCodeInput);
  }

  validateCodeInput = () => {
    console.log('input');
    console.log(this.codeInput.value);
    this.codeInput.value = this.codeInput.value.replace(/[^a-z0-9]/ig, '');
  }
}