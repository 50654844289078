export default class Navbar {
  constructor(el) {
    this.el = el;
    this.hambtn = this.el.querySelector('.js-ham-button');
    this.overlay = document.querySelector('.js-overlay');
    this.header = document.querySelector('.main-header');

    this.eventHandler();
  }

  eventHandler = () => {
    this.hambtn?.addEventListener('click', () => {
      const show = this.el.classList.toggle("flip");
      if (show){
        this.overlay.classList.remove('js-hide');
        this.header.style.zIndex = 11
      } else {
        this.overlay.classList.add('js-hide');
        this.header.style.zIndex = 'unset'
      }
    })
  }
}
