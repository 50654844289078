// The strings in this file were generated from the bugfixer:import_flags and export_flags
// tasks. However, for India ("in") and the Dominican Republic ("do"), I wrote special import
// code since their ISO codes are also Javascript reserved words.

import { in as in_ } from '../../../assets/images/in.svg';
import { do as do_ } from '../../../assets/images/do.svg';

import as from '../../../assets/images/as.svg';
import mp from '../../../assets/images/mp.svg';
import gn from '../../../assets/images/gn.svg';
import nc from '../../../assets/images/nc.svg';
import dm from '../../../assets/images/dm.svg';
import fk from '../../../assets/images/fk.svg';
import si from '../../../assets/images/si.svg';
import tg from '../../../assets/images/tg.svg';
import ps from '../../../assets/images/ps.svg';
import qa from '../../../assets/images/qa.svg';
import mr from '../../../assets/images/mr.svg';
import fj from '../../../assets/images/fj.svg';
import mv from '../../../assets/images/mv.svg';
import mh from '../../../assets/images/mh.svg';
import us from '../../../assets/images/us.svg';
import er from '../../../assets/images/er.svg';
import ug from '../../../assets/images/ug.svg';
import ci from '../../../assets/images/ci.svg';
import la from '../../../assets/images/la.svg';
import sr from '../../../assets/images/sr.svg';
import be from '../../../assets/images/be.svg';
import cx from '../../../assets/images/cx.svg';
import rw from '../../../assets/images/rw.svg';
import nu from '../../../assets/images/nu.svg';
import fr from '../../../assets/images/fr.svg';
import mn from '../../../assets/images/mn.svg';
import bg from '../../../assets/images/bg.svg';
import gs from '../../../assets/images/gs.svg';
import cn from '../../../assets/images/cn.svg';
import gp from '../../../assets/images/gp.svg';
import gf from '../../../assets/images/gf.svg';
import ro from '../../../assets/images/ro.svg';
import eg from '../../../assets/images/eg.svg';
import ir from '../../../assets/images/ir.svg';
import gw from '../../../assets/images/gw.svg';
import ma from '../../../assets/images/ma.svg';
import vg from '../../../assets/images/vg.svg';
import tc from '../../../assets/images/tc.svg';
import cy from '../../../assets/images/cy.svg';
import mg from '../../../assets/images/mg.svg';
import yt from '../../../assets/images/yt.svg';
import wf from '../../../assets/images/wf.svg';
import no from '../../../assets/images/no.svg';
import et from '../../../assets/images/et.svg';
import nr from '../../../assets/images/nr.svg';
import co from '../../../assets/images/co.svg';
import bn from '../../../assets/images/bn.svg';
import vc from '../../../assets/images/vc.svg';
import rs from '../../../assets/images/rs.svg';
import lv from '../../../assets/images/lv.svg';
import cf from '../../../assets/images/cf.svg';
import it from '../../../assets/images/it.svg';
import eu from '../../../assets/images/eu.svg';
import dz from '../../../assets/images/dz.svg';
import re from '../../../assets/images/re.svg';
import cr from '../../../assets/images/cr.svg';
import am from '../../../assets/images/am.svg';
import pl from '../../../assets/images/pl.svg';
import ht from '../../../assets/images/ht.svg';
import nf from '../../../assets/images/nf.svg';
import so from '../../../assets/images/so.svg';
import ne from '../../../assets/images/ne.svg';
import fo from '../../../assets/images/fo.svg';
import al from '../../../assets/images/al.svg';
import ch from '../../../assets/images/ch.svg';
import um from '../../../assets/images/um.svg';
import gq from '../../../assets/images/gq.svg';
import hn from '../../../assets/images/hn.svg';
import tj from '../../../assets/images/tj.svg';
import tn from '../../../assets/images/tn.svg';
import td from '../../../assets/images/td.svg';
import to from '../../../assets/images/to.svg';
import jo from '../../../assets/images/jo.svg';
import iq from '../../../assets/images/iq.svg';
import sz from '../../../assets/images/sz.svg';
import ke from '../../../assets/images/ke.svg';
import by from '../../../assets/images/by.svg';
import vu from '../../../assets/images/vu.svg';
import pa from '../../../assets/images/pa.svg';
import uy from '../../../assets/images/uy.svg';
import ru from '../../../assets/images/ru.svg';
import de from '../../../assets/images/de.svg';
import cm from '../../../assets/images/cm.svg';
import mk from '../../../assets/images/mk.svg';
import eo from '../../../assets/images/eo.svg';
import cc from '../../../assets/images/cc.svg';
import pk from '../../../assets/images/pk.svg';
import ge from '../../../assets/images/ge.svg';
import tl from '../../../assets/images/tl.svg';
import lu from '../../../assets/images/lu.svg';
import pr from '../../../assets/images/pr.svg';
import at from '../../../assets/images/at.svg';
import ky from '../../../assets/images/ky.svg';
import ee from '../../../assets/images/ee.svg';
import ws from '../../../assets/images/ws.svg';
import bm from '../../../assets/images/bm.svg';
import is from '../../../assets/images/is.svg';
import mx from '../../../assets/images/mx.svg';
import zz from '../../../assets/images/zz.svg';
import ua from '../../../assets/images/ua.svg';
import km from '../../../assets/images/km.svg';
import sh from '../../../assets/images/sh.svg';
import bt from '../../../assets/images/bt.svg';
import bw from '../../../assets/images/bw.svg';
import tw from '../../../assets/images/tw.svg';
import ca from '../../../assets/images/ca.svg';
import ck from '../../../assets/images/ck.svg';
import np from '../../../assets/images/np.svg';
import mf from '../../../assets/images/mf.svg';
import sg from '../../../assets/images/sg.svg';
import bo from '../../../assets/images/bo.svg';
import gl from '../../../assets/images/gl.svg';
import gr from '../../../assets/images/gr.svg';
import mz from '../../../assets/images/mz.svg';
import bd from '../../../assets/images/bd.svg';
import br from '../../../assets/images/br.svg';
import bv from '../../../assets/images/bv.svg';
import kr from '../../../assets/images/kr.svg';
import sb from '../../../assets/images/sb.svg';
import mq from '../../../assets/images/mq.svg';
import ga from '../../../assets/images/ga.svg';
import sd from '../../../assets/images/sd.svg';
import pt from '../../../assets/images/pt.svg';
import il from '../../../assets/images/il.svg';
import cg from '../../../assets/images/cg.svg';
import ml from '../../../assets/images/ml.svg';
import kg from '../../../assets/images/kg.svg';
import ie from '../../../assets/images/ie.svg';
import dj from '../../../assets/images/dj.svg';
import ae from '../../../assets/images/ae.svg';
import lk from '../../../assets/images/lk.svg';
import mo from '../../../assets/images/mo.svg';
import ph from '../../../assets/images/ph.svg';
import gg from '../../../assets/images/gg.svg';
import pe from '../../../assets/images/pe.svg';
import tv from '../../../assets/images/tv.svg';
import sa from '../../../assets/images/sa.svg';
import cv from '../../../assets/images/cv.svg';
import ai from '../../../assets/images/ai.svg';
import mm from '../../../assets/images/mm.svg';
import zw from '../../../assets/images/zw.svg';
import au from '../../../assets/images/au.svg';
import mw from '../../../assets/images/mw.svg';
import pf from '../../../assets/images/pf.svg';
import mc from '../../../assets/images/mc.svg';
import gb from '../../../assets/images/gb.svg';
import eh from '../../../assets/images/eh.svg';
import ax from '../../../assets/images/ax.svg';
import gu from '../../../assets/images/gu.svg';
import cw from '../../../assets/images/cw.svg';
import sn from '../../../assets/images/sn.svg';
import bb from '../../../assets/images/bb.svg';
import sc from '../../../assets/images/sc.svg';
import aq from '../../../assets/images/aq.svg';
import ag from '../../../assets/images/ag.svg';
import nl from '../../../assets/images/nl.svg';
import az from '../../../assets/images/az.svg';
import im from '../../../assets/images/im.svg';
import pn from '../../../assets/images/pn.svg';
import sl from '../../../assets/images/sl.svg';
import sk from '../../../assets/images/sk.svg';
import bs from '../../../assets/images/bs.svg';
import tr from '../../../assets/images/tr.svg';
import cz from '../../../assets/images/cz.svg';
import hr from '../../../assets/images/hr.svg';
import ba from '../../../assets/images/ba.svg';
import ni from '../../../assets/images/ni.svg';
import id from '../../../assets/images/id.svg';
import ly from '../../../assets/images/ly.svg';
import gm from '../../../assets/images/gm.svg';
import va from '../../../assets/images/va.svg';
import zm from '../../../assets/images/zm.svg';
import gi from '../../../assets/images/gi.svg';
import sv from '../../../assets/images/sv.svg';
import af from '../../../assets/images/af.svg';
import aw from '../../../assets/images/aw.svg';
import md from '../../../assets/images/md.svg';
import es from '../../../assets/images/es.svg';
import je from '../../../assets/images/je.svg';
import ao from '../../../assets/images/ao.svg';
import st from '../../../assets/images/st.svg';
import ad from '../../../assets/images/ad.svg';
import ss from '../../../assets/images/ss.svg';
import fm from '../../../assets/images/fm.svg';
import jm from '../../../assets/images/jm.svg';
import ye from '../../../assets/images/ye.svg';
import tt from '../../../assets/images/tt.svg';
import ms from '../../../assets/images/ms.svg';
import kz from '../../../assets/images/kz.svg';
import ar from '../../../assets/images/ar.svg';
import bz from '../../../assets/images/bz.svg';
import bq from '../../../assets/images/bq.svg';
import om from '../../../assets/images/om.svg';
import bh from '../../../assets/images/bh.svg';
import kw from '../../../assets/images/kw.svg';
import sy from '../../../assets/images/sy.svg';
import lb from '../../../assets/images/lb.svg';
import pm from '../../../assets/images/pm.svg';
import kn from '../../../assets/images/kn.svg';
import me from '../../../assets/images/me.svg';
import mt from '../../../assets/images/mt.svg';
import vi from '../../../assets/images/vi.svg';
import ng from '../../../assets/images/ng.svg';
import py from '../../../assets/images/py.svg';
import gt from '../../../assets/images/gt.svg';
import li from '../../../assets/images/li.svg';
import tm from '../../../assets/images/tm.svg';
import lt from '../../../assets/images/lt.svg';
import lr from '../../../assets/images/lr.svg';
import tz from '../../../assets/images/tz.svg';
import ve from '../../../assets/images/ve.svg';
import dk from '../../../assets/images/dk.svg';
import th from '../../../assets/images/th.svg';
import cu from '../../../assets/images/cu.svg';
import pw from '../../../assets/images/pw.svg';
import tf from '../../../assets/images/tf.svg';
import fi from '../../../assets/images/fi.svg';
import nz from '../../../assets/images/nz.svg';
import cl from '../../../assets/images/cl.svg';
import lc from '../../../assets/images/lc.svg';
import ki from '../../../assets/images/ki.svg';
import gd from '../../../assets/images/gd.svg';
import ec from '../../../assets/images/ec.svg';
import bf from '../../../assets/images/bf.svg';
import bl from '../../../assets/images/bl.svg';
import bi from '../../../assets/images/bi.svg';
import kh from '../../../assets/images/kh.svg';
import sj from '../../../assets/images/sj.svg';
import gy from '../../../assets/images/gy.svg';
import cd from '../../../assets/images/cd.svg';
import kp from '../../../assets/images/kp.svg';
import sx from '../../../assets/images/sx.svg';
import sm from '../../../assets/images/sm.svg';
import gh from '../../../assets/images/gh.svg';
import pg from '../../../assets/images/pg.svg';
import hm from '../../../assets/images/hm.svg';
import uz from '../../../assets/images/uz.svg';
import io from '../../../assets/images/io.svg';
import vn from '../../../assets/images/vn.svg';
import ls from '../../../assets/images/ls.svg';
import za from '../../../assets/images/za.svg';
import hu from '../../../assets/images/hu.svg';
import na from '../../../assets/images/na.svg';
import mu from '../../../assets/images/mu.svg';
import my from '../../../assets/images/my.svg';
import hk from '../../../assets/images/hk.svg';
import jp from '../../../assets/images/jp.svg';
import tk from '../../../assets/images/tk.svg';
import se from '../../../assets/images/se.svg';
import bj from '../../../assets/images/bj.svg';
import xk from '../../../assets/images/xk.svg';

export { in_ as in };
export { do_ as do };
export default {
  as,
  mp,
  gn,
  nc,
  dm,
  fk,
  si,
  tg,
  ps,
  qa,
  mr,
  fj,
  mv,
  mh,
  us,
  er,
  ug,
  ci,
  la,
  sr,
  be,
  cx,
  rw,
  nu,
  fr,
  mn,
  bg,
  gs,
  cn,
  gp,
  gf,
  ro,
  eg,
  ir,
  gw,
  ma,
  vg,
  tc,
  cy,
  mg,
  yt,
  wf,
  no,
  et,
  nr,
  co,
  bn,
  vc,
  rs,
  lv,
  cf,
  it,
  eu,
  dz,
  re,
  cr,
  am,
  pl,
  ht,
  nf,
  so,
  ne,
  fo,
  al,
  ch,
  um,
  gq,
  hn,
  tj,
  tn,
  td,
  to,
  jo,
  iq,
  sz,
  ke,
  by,
  vu,
  pa,
  uy,
  ru,
  de,
  cm,
  mk,
  eo,
  cc,
  pk,
  ge,
  tl,
  lu,
  pr,
  at,
  ky,
  ee,
  ws,
  bm,
  is,
  mx,
  zz,
  ua,
  km,
  sh,
  bt,
  bw,
  tw,
  ca,
  ck,
  np,
  mf,
  sg,
  bo,
  gl,
  gr,
  mz,
  bd,
  br,
  bv,
  kr,
  sb,
  mq,
  ga,
  sd,
  pt,
  il,
  cg,
  ml,
  kg,
  ie,
  dj,
  ae,
  lk,
  mo,
  ph,
  gg,
  pe,
  tv,
  sa,
  cv,
  ai,
  mm,
  zw,
  au,
  mw,
  pf,
  mc,
  gb,
  eh,
  ax,
  gu,
  cw,
  sn,
  bb,
  sc,
  aq,
  ag,
  nl,
  az,
  im,
  pn,
  sl,
  sk,
  bs,
  tr,
  cz,
  hr,
  ba,
  ni,
  id,
  ly,
  gm,
  va,
  zm,
  gi,
  sv,
  af,
  aw,
  md,
  es,
  je,
  ao,
  st,
  ad,
  ss,
  fm,
  jm,
  ye,
  tt,
  ms,
  kz,
  ar,
  bz,
  bq,
  om,
  bh,
  kw,
  sy,
  lb,
  pm,
  kn,
  me,
  mt,
  vi,
  ng,
  py,
  gt,
  li,
  tm,
  lt,
  lr,
  tz,
  ve,
  dk,
  th,
  cu,
  pw,
  tf,
  fi,
  nz,
  cl,
  lc,
  ki,
  gd,
  ec,
  bf,
  bl,
  bi,
  kh,
  sj,
  gy,
  cd,
  kp,
  sx,
  sm,
  gh,
  pg,
  hm,
  uz,
  io,
  vn,
  ls,
  za,
  hu,
  na,
  mu,
  my,
  hk,
  jp,
  tk,
  se,
  bj,
  xk,
};