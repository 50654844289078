import { observer } from '../helpers/observer';
import AudioForm from './audio';
import Documents from './documents';

export function AudioObserver(el) {
  const addNodeCallback = (addedNode) => {
    return new AudioForm(addedNode);
  }

  observer(el, addNodeCallback);
};

export function DocumentsObserver(el) {
  const addNodeCallback = (addedNode) => {
    return new Documents(addedNode);
  }

  observer(el, addNodeCallback);
};