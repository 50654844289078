import { observer } from '../helpers/observer';
import Summary from './summary';
import Payment from './payment';

export function summaryObserver(el) {
  const addNodeCallback = (addedNode) => {
    return new Summary(addedNode);
  }

  observer(el, addNodeCallback);
}

export function payMethodObserver(el) {
  const addNodeCallback = (addedNode) => {
    return new Payment(addedNode);
  }

  observer(el, addNodeCallback);
}