export default class TimeZoneConverter {
  constructor(el) {
    this.el = el;
    const utcTime = el.textContent.trim();
    const utcDate = new Date(utcTime + " UTC");

    const localTime = utcDate.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });

    el.textContent = localTime;
  }
}
