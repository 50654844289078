export function dropdown(el, selectEl) {
  const currentItem = el.querySelector('.js-dropdown-current');
  const dropdownList = el.querySelector('.js-dropdown-list');
  let isOpen = false;

  init();
  el.addEventListener('click', clickHandler);
  selectEl.addEventListener('change', init);

  const event = new Event('change');

  function clickHandler(event) {
    if (event.target === currentItem || event.target === el) {
      toggle();
    } else if (event.target.tagName === 'A') {
      setValue(event.target.dataset.id);
      close();
    }
  }

  function onDocumentClick(event) {
    if (!el.contains(event.target)) close();
  }

  function setValue(value) {
    selectEl.selectedIndex = [...selectEl.options].findIndex(item => item.value === value);
    selectEl.dispatchEvent(event);
  }

  function toggle() {
    if (isOpen) close()
    else open();
  }

  function open() {
    dropdownList.classList.remove('js-hide');
    document.addEventListener('click', onDocumentClick);
    isOpen = true;
  }

  function close() {
    dropdownList.classList.add('js-hide');
    document.removeEventListener('click', onDocumentClick);
    isOpen = false;
  }

  function init() {
    const selected = selectEl.selectedIndex;
    if (selected >= 0) {
      currentItem.textContent = selectEl.options[selected].text;
    } else {
      currentItem.textContent = 'Create IVR';
    }
  }
}