export function observer(el, addCallback=false, delCallback=false) {
  const callback = (allMutations) => {
    allMutations.map(item => {
      for (let node of item.addedNodes) {
        if (addCallback && (node instanceof HTMLElement)) {
          addCallback(node);
        }
      }
      for (let node of item.removedNodes) {
        if (delCallback && (node instanceof HTMLElement)) {
          delCallback(node);
        }
      }
    })
  }

  const observer = new MutationObserver(callback);

  const options = {
    'childList': true,
    'subtree': false,
  }

  observer.observe(el, options);
}