export const pagination = (items, prevBtn, nextBtn, currPage, allPages, pageSize) => {

  let currentPage = 1;
  const pagesCount = Math.ceil(items.length / pageSize);
  allPages.textContent = pagesCount;

  function eventHandler() {
    prevBtn.addEventListener('click', () => prevPage());
    nextBtn.addEventListener('click', () => nextPage());
  }

  function prevPage() {
    if (currentPage > 1) {
      currentPage--;
      changePage(currentPage);
    }
  }

  function nextPage() {
    if (currentPage < pagesCount) {
      currentPage++;
      changePage(currentPage);
    }
  }

  function changePage(page) {
    items.forEach(item => {
      item.classList.add('js-hide');
    });
    currPage.textContent = page;

    for (let i = (page - 1) * pageSize; i < (page * pageSize) && i < items.length; i++ ) {
      items[i].classList.remove('js-hide');
    }
  }

  changePage(1);
  eventHandler();
}