export class Modal {
  constructor(el) {
    this.el = el;
    this.overlay = document.querySelector('.js-overlay');
    this.closeBtn = this.el.querySelector('.js-close-modal-btn');
    this.resetBtn = this.el.querySelector('.js-reset-modal-btn');
    this.form = this.el.querySelector('.modal__form');

    this.eventHandler();
  }

  open = () => {
    this.overlay.classList.remove('js-hide');
    this.el.classList.remove('js-hide');
    this.el.classList.add('js-modal-opening');

    setTimeout(() => {
      this.el.classList.remove('js-modal-opening');
    }, 500);
  }

  close = () => {
    this.overlay.classList.add('js-hide');
    this.el.classList.add('js-modal-closing');

    setTimeout(() => {
      this.el.classList.remove('js-modal-closing');
      this.el.classList.add('js-hide');
    }, 500);

    this.overlay.removeEventListener('click', this.close);
    this.closeBtn.removeEventListener('click', this.close);

    if (this.el.classList.contains('js-rails-modal')) {
      setTimeout(() => this.el.remove(), 500);
    }
  }

  eventHandler = () => {
    this.overlay.addEventListener('click', this.close);
    this.closeBtn.addEventListener('click', this.close);
    this.resetBtn.addEventListener('click', this.close);
    this.form.addEventListener('ajax:success', () => this.close());
  }
}