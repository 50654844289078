export async function getData(url, spinner, searchInput, searchBtn) {
  loadingProgress(spinner, searchInput, searchBtn);
  const res = await fetch(url);
  if (!res.ok) {
    const err = await res.json();
    loadingEnd(spinner, searchInput, searchBtn);
    throw new Error(err.message);
  }
  const body = res.json();
  loadingEnd(spinner, searchInput, searchBtn);
  return body;
}

export async function getCountryNumbers(countryCode, spinner, searchInput, searchBtn) {
  const res = await getData(`${process.env.API}api/v2/dids/${countryCode}`, spinner, searchInput, searchBtn);
  return res;
}

export async function getCitiesNumber(countryCode, search, spinner, searchInput, searchBtn) {
  const res = await getData(`${process.env.API}api/v2/dids/${countryCode}${search}`, spinner, searchInput, searchBtn);
  return res;
}

const loadingProgress = (spinner, searchInput, searchBtn) => {
  spinner.removeAttribute('hidden');
  searchInput.setAttribute('disabled', '');
  searchBtn.setAttribute('disabled', '');
}

const loadingEnd = (spinner, searchInput, searchBtn) => {
  spinner.setAttribute('hidden', '');
  searchInput.removeAttribute('disabled');
  searchBtn.removeAttribute('disabled');
}