export default class A2pOption {
  constructor(el) {
    this.el = el;
    this.a2pOption = this.el.querySelector('.js-a2p-option');
    this.withTaxForm = this.el.querySelector('.js-with-tax-id-form');
    this.withoutTaxForm = this.el.querySelector('.js-without-tax-id-form');
    this.flashes = this.el.querySelectorAll('.settings__a2p-flash')
    this.hiddenA2pOptions = this.el.querySelectorAll('.js-tax-id-option-identifier')

    this.hiddenA2pOptions.forEach((input) => {
      input.value = '1';
    })

    this.eventHandler();
  }

  eventHandler = () => {
    this.a2pOption.addEventListener('change', this.formSelectHandler);
  }

  resetFlashes = () => {
    this.flashes.forEach((elem) => {
      elem.innerHTML = '';
    })
  }

  formSelectHandler = () => {
    const selectedOption = this.a2pOption.value;
    this.hiddenA2pOptions.forEach((input) => {
      input.value = selectedOption;
    })

    this.resetFlashes();
    if (selectedOption === '1') {
      this.withTaxForm.style.display = 'block';
      this.withoutTaxForm.style.display = 'none';
    } else if (selectedOption === '2') {
      this.withTaxForm.style.display = 'none';
      this.withoutTaxForm.style.display = 'block';
    }
  }
}
