import { getCitiesNumber, getCountryNumbers } from "../helpers/api-service";
import { pagination } from "../helpers/pagination";
import { dropdown } from "../helpers/dropdown";
import { numberTemplate } from "./number-template";
import flags from "../helpers/flags";

export default class AddNumberForm {
  constructor(el) {
    this.el = el;
    
    this.countryWrap = this.el.querySelector('.js-country-wrap');
    this.countrySelect = this.countryWrap.querySelector('.js-select-country');
    this.pagination = this.el.querySelector('.js-add-number-paginations');
    this.numbersList = this.el.querySelector('.js-numbers-list');
    this.numberSpinner = this.numbersList.querySelector('.js-add-numbers-spinner');
    this.errorMessage = this.numbersList.querySelector('.js-add-numbers-err');
    this.cityInput = this.el.querySelector('.js-city-search');
    this.searchBtn = this.el.querySelector('.js-search-btn');
    this.submitBtn = this.el.querySelector('.js-add-number-submit');
    this.submitSpinner = this.el.querySelector('.js-submit-spinner');

    this.url = new URL(process.env.API);
    this.countryCode = '';
    this.numbers = {};
    this.keyPressed = false;
    
    dropdown(this.countryWrap.querySelector('.js-country-dropdown'), this.countrySelect);
    this.eventHandler();
  }

  eventHandler = () => {
    this.countrySelect.addEventListener('change', this.countrySelectHandler);
    this.cityInput.addEventListener('input', this.validateSearchInput);
    this.cityInput.addEventListener('keydown', (e) => this.keyHandler(e));
    this.cityInput.addEventListener('keyup', () => {this.keyPressed = false});
    this.searchBtn.addEventListener('click', this.searchBtnHandler);

    this.el.addEventListener('ajax:send', this.formSendStart);
    this.el.addEventListener('ajax:success', this.formSendEnd);
    this.el.addEventListener('ajax:error', this.formSendEnd);
  }

  countrySelectHandler = async () => {
    const countryFlag = this.countryWrap.querySelector('.js-select-flag');
    this.countryCode = this.countrySelect.options[this.countrySelect.selectedIndex].value;
    if (this.countryWrap.classList.contains('js-not-selected')) {
      this.countrySelect.classList.remove('js-not-selected');
      this.countryWrap.classList.remove('js-not-selected');
    }
    countryFlag.setAttribute('src', flags[this.countryCode.toLowerCase()]);
    this.cityInput.value = '';
    this.citySelectHandleClear();
    this.clearItems(this.numbersList.querySelectorAll('.js-add-number-item'));
    try {
      this.numbers = await getCountryNumbers(this.countryCode, this.numberSpinner, this.cityInput, this.searchBtn);
      this.pagination.classList.remove('js-not-selected');
      this.getCityNumbers(this.numbers);
    } catch(error) {
      this.errorHandler(error);
    }
  }

  validateSearchInput = () => {
    this.cityInput.value = this.cityInput.value.replace(/[^a-z0-9*]/ig, '');
  }

  keyHandler = (e) => {
    if (!this.keyPressed) {
      this.keyPressed = true;
      if ((e.key === 'Enter') && (this.cityInput.value)) {
        e.preventDefault();
        this.citySearchHandler();
      }
    }
  }

  searchBtnHandler = () => {
    this.cityInput.value && this.citySearchHandler();
  }

  citySearchHandler = async () => {
    let params;
    let cityCode = this.cityInput.value;
    params = {contains: cityCode};

    this.citySelectHandleClear();
    this.clearItems(this.numbersList.querySelectorAll('.js-add-number-item'));
    this.url.search = new URLSearchParams(params).toString();
    try {
      this.numbers = await getCitiesNumber(this.countryCode, this.url.search, this.numberSpinner, this.cityInput, this.searchBtn);
      this.pagination.classList.remove('js-not-selected');
      this.getCityNumbers(this.numbers);
      this.cityInput.blur();
    } catch(error) {
      this.errorHandler(error);
    }
  }

  getCityNumbers = (numbers) => {
    Object.keys(numbers).map(key => {
      this.numbersList.insertAdjacentHTML('afterbegin', numberTemplate(this.countryCode, key, numbers[key]));
    });

    const allNumberItems = document.querySelectorAll('.js-add-number-item');
    const allNumberRadios = document.querySelectorAll('.js-add-number-radio');
    const prevPaginationBtn = document.querySelector('.js-prev-btn');
    const nextPaginationBtn = document.querySelector('.js-next-btn');
    const currentPage = document.querySelector('.add-number__paginations-current');
    const allPages = document.querySelector('.add-number__paginations-all');

    allNumberRadios.forEach(item => item.addEventListener('change', this.getNumberExtraData));
    pagination(allNumberItems, prevPaginationBtn, nextPaginationBtn, currentPage, allPages, 6);
  }

  getNumberExtraData = event => {
    this.clearHiddenFields();
    const { target } = event;
    const { location, purchaser, type } = this.searchCheckedNumber(target.value);
    target.insertAdjacentHTML('beforebegin', `
      <div class="add-number__number-hidden-field">
        <input type="text" name="location" value="${location}" class="visually-hidden">
        <input type="text" name="purchaser" value="${purchaser}" class="visually-hidden">
        <input type="text" name="type" value="${type}" class="visually-hidden">
      </div>
    `);
  }

  citySelectHandleClear = () => {
    const numbersPlaceholder = this.el.querySelector('.js-numbers-empty');
    if (!this.pagination.classList.contains('js-not-selected')) {
      this.pagination.classList.add('js-not-selected');
      document.querySelector('.add-number__paginations-current').textContent = 0;
      document.querySelector('.add-number__paginations-all').textContent = 0;
    }
    if(!this.errorMessage.hasAttribute('hidden')) {
      this.errorMessage.setAttribute('hidden', '');
    }
    numbersPlaceholder.classList.add('js-hide');
    this.numbersList.classList.remove('js-hide');
  }

  errorHandler = error => {
    this.errorMessage.removeAttribute('hidden');
    this.errorMessage.textContent = String(error).substring(7);
    this.pagination.classList.remove('js-not-selected');
  }

  clearItems = () => {  
    const numbers = this.numbersList.querySelectorAll('.js-add-number-item');
    numbers.forEach(item => {
      const numberRadio = item.querySelector('.js-add-number-radio');
      numberRadio.removeEventListener('change', this.getNumberExtraData)
      item.remove();
    });
  }

  searchCheckedNumber = (number) => {
    for (let key in this.numbers) {
      if (key === number) return this.numbers[key];
    }
  }

  clearHiddenFields = () => {
    const numbers = this.numbersList.querySelectorAll('.js-add-number-item');
    numbers.forEach(item => {
      const hiddenFieldBlock = item.querySelector('.add-number__number-hidden-field');
      hiddenFieldBlock && hiddenFieldBlock.remove();
    })
  }

  formSendStart = () => {
    this.submitSpinner.removeAttribute('hidden');
    this.submitBtn.setAttribute('disabled', true);
  }
  formSendEnd = (event) => {
    const {type} = event;
    const [response] = event.detail;
    this.submitSpinner.setAttribute('hidden', '');
    this.submitBtn.removeAttribute('disabled');
    if (type === 'ajax:success') {
      document.querySelector('.js-link-to-numbers').click();
    } else {
      console.log(response);
    }
  }
}