import { LOCAL_API_URL, STRIPE_PUBLISH_KEY } from '../constants';
import { showFlash } from '../helpers/showFlash';
import { animatedRemove } from '../helpers/animatedRemove';

export function creditCardHandler(subscriptionId, paymentBtnEl, spinnerEl, flashMessageEl, {
    isChanged = true, 
    fixedAmount = null, 
    invoiceId = null,
    invoiceForm = null,
    invoiceFlash = null,
  } = {}) {
  const stripe = Stripe(STRIPE_PUBLISH_KEY);

  paymentBtnEl.addEventListener('click', async e => {
    e.preventDefault();
    sendStart();
    const amount = isChanged ? document.querySelector('.add-credit__radio:checked').value : fixedAmount;
    const payload = {
      amount: amount,
      subscription_id: subscriptionId, 
    };
    if (invoiceId) payload.invoice_id = invoiceId;
    try {
      await stripeRequestsHandler(payload);
      showFlash(flashMessageEl, "Your payment succeeded");
      if (invoiceId) {
        await sendPaidInvoice(invoiceId);
        animatedRemove(invoiceForm);
      }
    } catch (err) {
      showFlash(flashMessageEl, err);
    } finally {
      sendEnd();
    }
  })

  async function stripeRequestsHandler(payload) {
    const result = await fetch(`${LOCAL_API_URL}/api/v1/orders/stripe/create-general-payment-intent?${new URLSearchParams(payload)}`, { method: 'POST' });
    const { client_secret, payment_method_id, message } = await result.json();
    if (message) throw new Error(message)
    const { paymentIntent, error } = await stripe.confirmCardPayment(client_secret, {payment_method: payment_method_id});
    if (error) throw new Error(error.message);
  }

  async function sendPaidInvoice(id) {
    try {
      const result = await fetch(`${LOCAL_API_URL}/api/v1/invoices/mark-as-paid?${new URLSearchParams({invoice_id: id})}`, 
        { method: 'POST' });
      const { message } = await result.json();
      showFlash(invoiceFlash, message);
    } catch (err) {
      throw new Error(err);
    }
  }

  function sendStart() {
    spinnerEl.removeAttribute('hidden');
    paymentBtnEl.setAttribute('disabled', true);
  }

  function sendEnd() {
    spinnerEl.setAttribute('hidden', '')
    paymentBtnEl.removeAttribute('disabled');
  }
}