import { uploader } from '../helpers/uploader';

export default class AudioForm {
  constructor(el) {
    this.el = el;
    this.form = this.el.querySelector('.js-audio-form');
    this.uploader = this.form.querySelector('.js-audio-upload');
    this.delBtns = this.el.querySelectorAll('.js-del-audio');

    this.eventHandler();
    uploader(this.form, this.uploader, 'audio', this.submitTrigger);
  }

  eventHandler = () => {
    this.delBtns.forEach(item => item.addEventListener("click", () => item.closest('.audio__item').remove()));  
  }

  submitTrigger = () => {
    this.form.querySelector('.js-download-btn').click();
  }
}