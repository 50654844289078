export function uploader(form, fileInput, blockClassName, inputChangeCallback) {
  function eventHandler() {
    fileInput.addEventListener('change', () => {
      if (fileInput.files[0].size > 3000000) {
        alert("File size should be less than 3 megabytes");
        fileInput.value = '';
        return
      };
      inputChangeCallback();
    });

    fileInput.addEventListener('direct-upload:initialize', event => {
      const { detail } = event;
      const { id, file } = detail;
      form.insertAdjacentHTML("beforeend", `
        <div id="direct-upload-${id}" class="${blockClassName}__upload ${blockClassName}__upload--pending">
          <div id="direct-upload-progress-${id}" class="${blockClassName}__upload-progress" style="width: 0%"></div>
          <span class="${blockClassName}__upload-filename"></span>
        </div>
      `);
      form.querySelector(`.${blockClassName}__upload-filename`).textContent = file.name;
    });

    fileInput.addEventListener("direct-upload:start", event => {
      const { id } = event.detail;
      const element = document.getElementById(`direct-upload-${id}`);
      element.classList.remove(`${blockClassName}__upload--pending`);
    });
    
    fileInput.addEventListener("direct-upload:progress", event => {
      const { id, progress } = event.detail;
      const progressElement = document.getElementById(`direct-upload-progress-${id}`);
      progressElement.style.width = `${progress}%`;
    });

    fileInput.addEventListener("direct-upload:error", event => {
      event.preventDefault()
      const { id, error } = event.detail;
      const element = document.getElementById(`direct-upload-${id}`);
      element.insertAdjacentHTML("afterend", `
        <div id="js-upload-err-message" class="${blockClassName}__upload-err">Your upload failed</div>
      `);
      setTimeout(() => {
        document.querySelector('#js-upload-err-message').remove()
      }, 5000);
      element.classList.add(`${blockClassName}__upload--error`);
      element.setAttribute("title", error);
    })

    fileInput.addEventListener("direct-upload:end", event => {
      const { id } = event.detail;
      const element = document.getElementById(`direct-upload-${id}`);
      element.classList.add(`${blockClassName}__upload--complete`);
      fileInput.value = null;
    })
  }

  eventHandler();
}