export function showFlash(el, message) {
  el.classList.contains('flash') && el.classList.remove('flash');
  el.classList.add('flash');
  el.textContent = message;
  setTimeout(() => hideFlash(el), 12000);
}

function hideFlash(el) {
  el.classList.remove('flash');
  el.textContent = '';
}