import flags from '../helpers/flags';

export function numberTemplate(country, number, numberObj) {
  const numberElement = `
    <li class="add-number__number-item js-add-number-item">
      <input type="radio" name="added-number" value="${number}" id="num-${number}" class="visually-hidden add-number__number-radio js-add-number-radio" required>
      <label class="add-number__number-label-wrap" for="num-${number}">
        <span class="add-number__number-flag-wrap">
          <img class="add-number__number-flag" src=${flags[country.toLowerCase()]} >
        </span>
        <span class="add-number__number-info">
          <span class="add-number__number-num">+${number}</span>
          ${numberObj.type === 'toll_free' ? `<span class="add-number__number-type">${numberObj.type.replace('_', ' ')}</span>` : `<span class="add-number__number-location">${numberObj.location}</span>`}
          <span class="add-number__capabilities">
            ${numberObj.sms ? '<span class="add-number__capabilities-item"/>SMS</span>' : '' }
            ${numberObj.mms ? '<span class="add-number__capabilities-item"/>MMS</span>' : '' }
            ${numberObj.voice ? '<span class="add-number__capabilities-item"/>Voice</span>' : '' }
          </span>
        </span>
      </label>
      <div class="add-number__number-tips-wrap">
        <svg width="16" height="16" class="add-number__tips-logo" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99984 1.99996C4.68613 1.99996 1.99984 4.68625 1.99984 7.99996C1.99984 11.3137 4.68613 14 7.99984 14C11.3135 14 13.9998 11.3137 13.9998 7.99996C13.9998 4.68625 11.3135 1.99996 7.99984 1.99996ZM0.666504 7.99996C0.666504 3.94987 3.94975 0.666626 7.99984 0.666626C12.0499 0.666626 15.3332 3.94987 15.3332 7.99996C15.3332 12.05 12.0499 15.3333 7.99984 15.3333C3.94975 15.3333 0.666504 12.05 0.666504 7.99996Z" fill="#8C8C8C"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00016 7.33337C8.36835 7.33337 8.66683 7.63185 8.66683 8.00004V10.6667C8.66683 11.0349 8.36835 11.3334 8.00016 11.3334C7.63197 11.3334 7.3335 11.0349 7.3335 10.6667V8.00004C7.3335 7.63185 7.63197 7.33337 8.00016 7.33337Z" fill="#8C8C8C"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.3335 5.33329C7.3335 4.9651 7.63197 4.66663 8.00016 4.66663H8.00683C8.37502 4.66663 8.6735 4.9651 8.6735 5.33329C8.6735 5.70148 8.37502 5.99996 8.00683 5.99996H8.00016C7.63197 5.99996 7.3335 5.70148 7.3335 5.33329Z" fill="#8C8C8C"/>
        </svg>
        <div class="add-number__number-tips">
          ${numberObj.prorated_price ? `<p class="add-number__number-tips-text">Today: $${numberObj.prorated_price} prorated and due again on ${numberObj.prorated_until_string}</p>` : ''}
          ${numberObj.price ? `<p class="add-number__number-tips-text">$${numberObj.price} per month</p>` : ''}
          ${numberObj.price_year ? `<p class="add-number__number-tips-text">$${numberObj.price_year} per year</p>` : ''}
        </div>
      </div>
    </li>
  `;
  
  return numberElement;
}
