import { Modal } from '../helpers/modal';

export default class Summary {
  constructor(el) {
    this.el = el;
    this.editBtn = this.el.querySelector('.js-edit-email');
    this.downgradeBtn = this.el.querySelector('.js-downgrade-btn');
    this.upgradeBtn = this.el.querySelector('.js-upgrade-btn');
    this.monthlyPlanBtn = this.el.querySelector('.js-monthly-btn');
    this.yearlyPlanBtn = this.el.querySelector('.js-yearly-btn');

    this.eventHandler();
  }

  eventHandler = () => {
    this.editBtn.addEventListener('ajax:success', this.openEditModal);
    this.downgradeBtn && this.downgradeBtn.addEventListener('ajax:success', this.openConfirmModal);
    this.upgradeBtn && this.upgradeBtn.addEventListener('ajax:success', this.openConfirmModal);
    this.monthlyPlanBtn && this.monthlyPlanBtn.addEventListener('ajax:success', this.openConfirmModal);
    this.yearlyPlanBtn && this.yearlyPlanBtn.addEventListener('ajax:success', this.openConfirmModal);
  }

  openEditModal = () => {
    const modal = document.querySelector('.js-modal-email');
    const newModal = new Modal(modal);
    newModal.open();
  }

  openConfirmModal = () => {
    const modal = document.querySelector('.js-modal-confirm');
    const newModal = new Modal(modal);
    newModal.open();
  }
}