export const getActionBySelect = (selectEl, actions, actionsContainer) => {
  const hideGroup = (actions) => {
    actions.forEach(item => {
      item.remove();
    })
  }
  const showGroup = (index, actions, actionsContainer) => {
    if (!actionsContainer.contains(actions[index])) {
      actionsContainer.insertAdjacentElement('afterbegin', actions[index])
    }
  }

  hideGroup(actions);
  showGroup(selectEl.options.selectedIndex, actions, actionsContainer);
  selectEl.addEventListener('change', () => {
    hideGroup(actions);
    showGroup(selectEl.options.selectedIndex, actions, actionsContainer);
  })
}