/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//= require jquery
require("@rails/ujs").start();
//= require_tree .
//= require activestorage

//= require jquery
//= require jquery_ujs
//= require_tree .
//= require turbolinks

//= require highcharts/highcharts
//= require highcharts/highcharts-more
//= require highcharts/highstock

import NumberItem from './numbers/number-item';
import PendingNumber from './numbers/pending-item';
import AddNumberForm from './numbers/add-number';
import VoicemailForm from './numbers/voicemail';
import IvrOption from './numbers/ivr/ivr';
import A2pOption from './numbers/a2p-option';
import Payment from './subscription/payment';
import Summary from './subscription/summary';
import AudioForm from './resources/audio';
import Documents from './resources/documents';
import SMSCallItem from "./call_messages/sms-call-item";
import TimeZoneConverter from "./time_zone_converter/time-zone-converter";
import Navbar from  "./header/navbar";

import { clearCalls } from './calls-messages';
import { IvrObserver } from './numbers/ivr/ivr-observers';
import { AudioObserver, DocumentsObserver } from './resources/resources-observer';
import { summaryObserver, payMethodObserver } from './subscription/subscription-observer';
import { verifyHandler } from './verify_cc/verify-handler';
import addCreditHandler from './subscription/add-credit-handler';
import invoicesHandler from './subscription/invoices-handler';
import * as ActiveStorage from "@rails/activestorage";

window.addEventListener('DOMContentLoaded', () => {
  console.log('Hello World from Webpacker');
  ActiveStorage.start();

  document.querySelectorAll('.js-number-item').forEach(item => new NumberItem(item));
  document.querySelectorAll('.js-pending-item').forEach(item => new PendingNumber(item));
  document.querySelectorAll('.js-add-number-form').forEach(item => new AddNumberForm(item));
  document.querySelectorAll('.js-voicemail-form').forEach(item => new VoicemailForm(item));
  document.querySelectorAll('.js-ivr-point').forEach(item => new IvrOption(item));
  document.querySelectorAll('.js-a2p-form').forEach(item => new A2pOption(item));
  document.querySelectorAll('.pay-method').forEach(item => new Payment(item));
  document.querySelectorAll('.js-audio-section').forEach(item => new AudioForm(item));
  document.querySelectorAll('.js-summary').forEach(item => new Summary(item));
  document.querySelectorAll('.js-documents').forEach(item => new Documents(item));
  document.querySelectorAll('.js-calls-item').forEach(item => new SMSCallItem(item));
  document.querySelectorAll('.js-timezone-convert').forEach(item => new TimeZoneConverter(item));
  document.querySelectorAll('.js-nav').forEach(item => new Navbar(item));

  document.querySelectorAll('.js-history-form').forEach(item => clearCalls(item));
  document.querySelectorAll('.js-ivr-wrapper').forEach(item => IvrObserver(item));
  document.querySelectorAll('.js-audio-page').forEach(item => AudioObserver(item));
  document.querySelectorAll('.js-documents-page').forEach(item => DocumentsObserver(item));
  document.querySelectorAll('.js-subscription-sum').forEach(item => summaryObserver(item));
  document.querySelectorAll('.js-pay-method-section').forEach(item => payMethodObserver(item));
  document.querySelectorAll('.js-add-credit-section').forEach(item => addCreditHandler());
  document.querySelectorAll('.js-invoice-section').forEach(item => invoicesHandler());
  verifyHandler();
});
