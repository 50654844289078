import {Modal} from '../helpers/modal';

export default class SMSCallItem {
  constructor(el) {
    this.el = el;
    this.callbackBtn = this.el.querySelector('.js-callback-btn');
    this.smsBtn = this.el.querySelector('.js-sms-btn');

    this.eventHandler();
  }

  eventHandler = () => {
    this.callbackBtn?.addEventListener('ajax:success', () => this.openModal('.js-modal-callback'));
    this.smsBtn?.addEventListener('ajax:success', () => this.openModal('.js-modal-sms'));
  }

  openModal = (selector) => {
    const modal = document.querySelector(`${selector}`);
    const newModal = new Modal(modal);
    newModal.open();
  }
}
