import {getActionBySelect} from '../../helpers/selectAction';
import {addFileOption} from '../../helpers/uploadIntoSelect';
import {dropdown} from '../../helpers/dropdown';
import {IvrActionObserver} from './ivr-observers';
import { Modal } from '../../helpers/modal';
import IvrAction from './ivr-action';

export default class IvrOption {
  constructor(el) {
    this.el = el;
    this.form = this.el.querySelector('.js-ivr-form');
    this.greetingSelect = this.form.querySelector('.js-ivr-action-select');
    this.greetingActions = this.form.querySelectorAll('.js-action-group');
    this.greetingActionsWrap = this.form.querySelector('.js-action-container');

    this.actionsList = this.el.querySelector('.js-ivr-actions-wrapper');
    this.ivrActions = this.actionsList.querySelectorAll('.js-ivr-action');

    //this.fileInput = this.form.querySelector('.js-action-message-file');
    this.fileSelect = this.form.querySelector('.js-files-select');

    this.addActionBtn = this.form.querySelector('.js-ivr-add-action');
    this.addIvrBtn = this.form.querySelector('.js-ivr-add-btn');
    this.delIvrBtn = this.form.querySelector('.js-ivr-del-btn');

    this.ivrSelect = this.form.querySelector('.js-ivr-select');
    this.ivrName = this.form.querySelector('.js-ivr-change-name-block');
    this.ivrNameInput = this.ivrName.querySelector('.js-ivr-change-name-input');
    this.ivrChangeNameBtn = this.form.querySelector('.js-ivr-change-name-btn');
    this.ivrLinks = this.form.querySelectorAll('.settings__ivr-link');

    this.keyPressed = false;
    
    dropdown(this.form.querySelector('.js-ivr-dropdown'), this.ivrSelect);
    this.initActions();
    this.eventHandler();
    IvrActionObserver(this.actionsList);
    getActionBySelect(this.greetingSelect, this.greetingActions, this.greetingActionsWrap);
  }

  eventHandler = () => {
    //this.fileInput.addEventListener('change', () => addFileOption(this.fileInput, this.fileSelect));
    this.ivrChangeNameBtn.addEventListener('click', this.showInput);
    this.ivrNameInput.addEventListener('keydown', (e) => this.keyHandler(e));
    this.ivrNameInput.addEventListener('keyup', () => {this.keyPressed = false});
    this.addIvrBtn.addEventListener('ajax:success', () => this.openIvrModal());
    this.delIvrBtn.addEventListener('ajax:success', () => location.reload());
    this.addActionBtn.addEventListener('ajax:success', () => this.scrollToAction());
  }

  initActions = () => {
    this.ivrActions.forEach(item => new IvrAction(item));
    if (this.ivrActions.length >= 12) {
      this.addActionBtn.classList.add('js-hide');
    }
  }

  keyHandler = (e) => {
    if (!this.keyPressed) {
      this.keyPressed = true;
      if ((e.key === 'Enter') || (e.key === 'Escape')) {
        e.preventDefault();
        this.saveInputChanges();
      }
    }
  }
  
  showInput = () => {
    this.ivrName.classList.remove('js-hide');
    this.ivrNameInput.focus();
    this.ivrNameInput.addEventListener('focusout', this.saveInputChanges);
  }

  hideInput = () => {
    this.ivrName.classList.add('js-hide');
    this.ivrNameInput.removeEventListener('focusout', this.saveInputChanges);
  }

  saveInputChanges = () => {
    const value = this.ivrNameInput.value;
    const selectedIvrOption = this.ivrSelect.options[this.ivrSelect.selectedIndex];
    selectedIvrOption.textContent = value;
    this.form.querySelector('.js-dropdown-current').textContent = value;
    this.hideInput();
  }

  openIvrModal = () => {
    const modal = document.querySelector('.js-modal-ivr');
    const newModal = new Modal(modal);
    newModal.open();
  }

  scrollToAction = () => {
    console.log('click');
    // const submitBtn = this.el.querySelector('.js-ivr-submit');
    // submitBtn.click();
    // setTimeout(() => {
    //   const ivrLayout = document.querySelector('.js-ivr-layout');
    //   ivrLayout.scrollTop = ivrLayout.scrollHeight;
    // }, 500);
  }
}

