import { Modal } from '../helpers/modal'; 

export default class Payment {
  constructor(el) {
    this.el = el;
    this.addCardBtn = this.el.querySelector('.js-add-card-btn');
    this.addCardModal = document.querySelector('.js-modal-add-card');
    this.payToggle = this.el.querySelector('.js-pay-method-toggle');

    this.eventHandler();
  }

  eventHandler = () => {
    this.addCardBtn && this.addCardBtn.addEventListener('click', this.openAddCardModal);
    this.payToggle.addEventListener('ajax:send', this.sendingHandler);
  }

  openAddCardModal = () => {
    const cardModal = new Modal(this.addCardModal);
    cardModal.open()
  }

  sendingHandler = () => {
    const payMethodWrap = this.el.querySelector('.pay-method__method');
    const spinner = this.el.querySelector('.spinner');

    payMethodWrap.innerHTML = '';
    spinner.removeAttribute('hidden');
  }
}