import { renderPaypalBtn } from './paypal-payment-handler';
import { creditCardHandler } from './stripe-payment-handler';

function addCreditHandler() {

  const paypalBtn = document.querySelector('#paypal-btn');
  const amountInputs = document.querySelectorAll('.add-credit__radio');
  const flashMessage = document.querySelector('.js-flash-message');
  const isPaypal = document.body.contains(paypalBtn);

  const addCreditSubmit = document.querySelector('.js-add-credit-submit');
  const spinner = document.querySelector('.js-add-credit-spinner');

  const subscriptionId = document.querySelector('#js-subscription').value;
  const token = document.querySelector('.add-credit__form').authenticity_token.value;

  if (isPaypal) {
    renderPaypalBtn(token, subscriptionId, paypalBtn, flashMessage, true, 0, amountInputs);
  } else {
    creditCardHandler(subscriptionId, addCreditSubmit, spinner, flashMessage, {isChanged: true});
  }

  // function creditCardHandler() {
  //   const stripe = Stripe(STRIPE_PUBLISH_KEY);

  //   const addCreditForm = document.querySelector('.js-add-credit-form');
    // const addCreditSubmit = document.querySelector('.js-add-credit-submit');
    // const spinner = document.querySelector('.js-add-credit-spinner');

  //   // addCreditForm.addEventListener('ajax:send', sendStart);
  //   // addCreditForm.addEventListener('ajax:success', sendEnd);
  //   // addCreditForm.addEventListener('ajax:error', sendEnd);

  //   addCreditSubmit.addEventListener('click', async e => {
  //     e.preventDefault();
  //     sendStart();
  //     const amount = document.querySelector('.add-credit__radio:checked').value;
  //     const payload = {
  //       amount: amount,
  //       subscription_id: subscriptionId,
  //     };

  //     try {
  //       const result = await fetch(`${LOCAL_API_URL}/api/v1/orders/stripe/create-general-payment-intent?${new URLSearchParams(payload)}`, { method: 'POST' });
  //       const { client_secret, payment_method_id } = await result.json();
  //       const { paymentIntent, error } = await stripe.confirmCardPayment(client_secret, {payment_method: payment_method_id});
  //       if (error) throw new Error(error.message);
  //       // TODO: after receiving the paymentIntent, send it to the backend
  //       // fetch(paymentIntent) ----- like this, need endpoint
  //       console.log(paymentIntent);
  //       // Future improvement: Localize this text and fetch it from localized strings.
  //       showFlash(flashMessage, "Your payment succeeded");
  //     } catch (err) {
  //       console.log('error: ', err);
  //       showFlash(flashMessage, err);
  //     } finally {
  //       sendEnd();
  //     }
  //   })

  //   function sendStart() {
  //     spinner.removeAttribute('hidden');
  //     addCreditSubmit.setAttribute('disabled', true);
  //   }

  //   function sendEnd() {
  //     spinner.setAttribute('hidden', '')
  //     addCreditSubmit.removeAttribute('disabled');
  //   }
  // }

  // function renderPaypalBtn() {
  //   paypalBtn && loadScript();
  //   paypalBtn && amountInputs.forEach(item => item.addEventListener('change', initPaypalBtn));

  //   function loadScript() {
  //     const script = document.createElement("script");
  //     script.src = `https://www.paypal.com/sdk/js?client-id=${PAYPAL_ID}&vault=false&intent=capture&disable-funding=card`;
  //     document.body.insertAdjacentElement('beforeend', script);
  //     script.addEventListener('load', initPaypalBtn);
  //   }

  //   function initPaypalBtn() {
  //     if (window.paypal.Buttons.instances) {
  //       window.paypal.Buttons.instances.map(instance => instance.close());
  //     }

  //     let payData;
  //     const amount = document.querySelector('.add-credit__radio:checked').value;

  //     window.paypal.Buttons({
  //       createOrder: async function(data, actions) {
  //         try {
  //           const paymentUrl = createURLWithParams(
  //             `${LOCAL_API_URL}/api/v1/orders/paypal/create-payment`, 
  //             {authenticity_token: token, amount: amount}
  //           );
  //           const res = await fetch(`${paymentUrl}${paymentUrl.search}`, {method: 'POST'});
  //           payData = await res.json();
  //           return payData.token;
  //         } catch(error) {
  //           console.error(error.message);
  //         }
  //       },
  //       onApprove: async function(data, actions) {
  //         try {
  //           const commitUrl = createURLWithParams(
  //             `${LOCAL_API_URL}/api/v1/orders/paypal/commit-payment`, 
  //             {payment_id: payData.payment_id, subscription_id: subscriptionId}
  //           );
  //           const res = await fetch(`${commitUrl}${commitUrl.search}`, {method: 'POST'});
  //           const result = await res.json();
  //           showFlash(flashMessage, result.message);
  //         } catch(error) {
  //           showFlash(flashMessage, error.message);
  //         }
  //       }
  //     }).render(paypalBtn);    
  //   }

  //   function createURLWithParams(url, params={}) {
  //     const newURL = new URL(url);
  //     newURL.search = new URLSearchParams(params).toString();
  //     return newURL;
  //   }
  // }

  // function showFlash(el, message) {
  //   el.classList.add('flash');
  //   el.textContent = message;
  // }
}

export default addCreditHandler;