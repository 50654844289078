import { creditCardHandler } from './stripe-payment-handler';
import { PAYPAL_ID, LOCAL_API_URL } from '../constants';
import { showFlash } from '../helpers/showFlash';

function invoicesHandler() {
  const invoiceForms = document.querySelectorAll('.js-invoices-form');
  const paypalBtn = document.querySelector('#paypal-btn');
  const isPaypal = document.body.contains(paypalBtn);
  const subscriptionId = document.querySelector('#js-subscription').value;

  if (isPaypal) {
    loadPaypalScript(invoiceForms, subscriptionId)
  } else {
    invoiceForms.forEach(form => invoiceForm(form, subscriptionId))
  };
}

function invoiceForm(form, subscriptionId) {
  const submitBtn = form.querySelector('.js-invoices-submit');
  const spinner = form.querySelector('.js-invoices-spinner');
  const flashMessage = form.querySelector('.js-flash-message');
  const amountValue = form.querySelector('#js-invoices-amount').value;
  const invoiceId = form.querySelector('#js-invoices-id').value;
  const invoiceFlash = document.querySelector('.js-success-payment-flash');

  creditCardHandler(subscriptionId, submitBtn, spinner, flashMessage, {
    isChanged: false, 
    fixedAmount: amountValue, 
    invoiceId: invoiceId,
    invoiceForm: form,
    invoiceFlash: invoiceFlash,
  });
}

function loadPaypalScript(invoiceForms, subscriptionId) {
  const script = document.createElement("script");
  script.classList.add('js-paypal-script');
  script.src = `https://www.paypal.com/sdk/js?client-id=${PAYPAL_ID}&vault=false&intent=capture&disable-funding=card`;
  document.body.insertAdjacentElement('beforeend', script);
  script.addEventListener('load', () => initPaypalBtn(invoiceForms, subscriptionId));
}

function initPaypalBtn(invoiceForms, subscriptionId) {

  invoiceForms.forEach(form => {
    const paypalBtnEl = form.querySelector('#paypal-btn');
    const token = form.authenticity_token.value;
    const flashMessageEl = form.querySelector('.js-flash-message');
    const amount = form.querySelector('#js-invoices-amount').value;

    let payData;

    window.paypal.Buttons({
      createOrder: async function(data, actions) {
        try {
          const paymentUrl = createURLWithParams(
            `${LOCAL_API_URL}/api/v1/orders/paypal/create-payment`, 
            {authenticity_token: token, amount: amount}
          );
          const res = await fetch(`${paymentUrl}${paymentUrl.search}`, {method: 'POST'});
          payData = await res.json();
          return payData.token;
        } catch(error) {
          console.error(error.message);
        }
      },
      onApprove: async function(data, actions) {
        try {
          const commitUrl = createURLWithParams(
            `${LOCAL_API_URL}/api/v1/orders/paypal/commit-payment`, 
            {payment_id: payData.payment_id, subscription_id: subscriptionId}
          );
          const res = await fetch(`${commitUrl}${commitUrl.search}`, {method: 'POST'});
          const result = await res.json();
          showFlash(flashMessageEl, result.message);
        } catch(error) {
          showFlash(flashMessageEl, error.message);
        }
      }
    }).render(paypalBtnEl);
  })  
}

function createURLWithParams(url, params={}) {
  const newURL = new URL(url);
  newURL.search = new URLSearchParams(params).toString();
  return newURL;
}

export default invoicesHandler;
