import {getActionBySelect} from '../../helpers/selectAction';
import {addFileOption} from '../../helpers/uploadIntoSelect';

export default class IvrAction {
  constructor(el) {
    this.el = el;

    //this.messageFileInput = this.el.querySelector('.js-action-message-file');
    //this.messageFileSelect = this.el.querySelector('.js-action-message-files-select');

    //this.digitFileInput =  this.el.querySelector('.js-ivr-digit-file');
    //this.digitFileSelect = this.el.querySelector('.js-ivr-digit-files-select');

    this.actionDelBtn = this.el.querySelector('.js-ivr-del-action');

    getActionBySelect(this.el.querySelector('.js-action-message-select'), this.el.querySelectorAll('.js-action-message-group'), this.el.querySelector('.js-action-message-container'));
    getActionBySelect(this.el.querySelector('.js-ivr-digit-action'), this.el.querySelectorAll('.js-ivr-digit-group'), this.el.querySelector('.js-digit-actions-container'));
    this.eventHandler();
  }

  eventHandler = () => {
    //this.messageFileInput.addEventListener('change', () => addFileOption(this.messageFileInput, this.messageFileSelect));
    //this.digitFileInput.addEventListener('change', () => addFileOption(this.digitFileInput, this.digitFileSelect));
    this.actionDelBtn.addEventListener('ajax:success', () => this.removeAction());
  }

  removeAction = () => {
    const removedItemId = this.el.dataset.id;
    const removedActionIdField = document.querySelector(`#ivr_option_ivr_actions_attributes_${removedItemId}_id`);
    removedActionIdField.remove();
    this.el.remove();
  }
}