import { PAYPAL_ID, LOCAL_API_URL } from '../constants';
import { showFlash } from '../helpers/showFlash';

export function renderPaypalBtn(token, subscriptionId, paypalBtnEl, flashMessageEl, isChanged, fixedAmount, inputs) {
  paypalBtnEl && loadScript();
  if (isChanged) {
    paypalBtnEl && inputs.forEach(item => item.addEventListener('change', initPaypalBtn));
  }

  function loadScript() {
    const script = document.createElement("script");
    script.src = `https://www.paypal.com/sdk/js?client-id=${PAYPAL_ID}&vault=false&intent=capture&disable-funding=card`;
    document.body.insertAdjacentElement('beforeend', script);
    script.addEventListener('load', initPaypalBtn);
  }

  function initPaypalBtn() {
    if (window.paypal.Buttons.instances) {
      window.paypal.Buttons.instances.map(instance => instance.close());
    }

    let payData;
    const amount = isChanged ? document.querySelector('.add-credit__radio:checked').value : fixedAmount;

    window.paypal.Buttons({
      createOrder: async function(data, actions) {
        try {
          const paymentUrl = createURLWithParams(
            `${LOCAL_API_URL}/api/v1/orders/paypal/create-payment`, 
            {authenticity_token: token, amount: amount}
          );
          const res = await fetch(`${paymentUrl}${paymentUrl.search}`, {method: 'POST'});
          payData = await res.json();
          return payData.token;
        } catch(error) {
          console.error(error.message);
        }
      },
      onApprove: async function(data, actions) {
        try {
          const commitUrl = createURLWithParams(
            `${LOCAL_API_URL}/api/v1/orders/paypal/commit-payment`, 
            {payment_id: payData.payment_id, subscription_id: subscriptionId}
          );
          const res = await fetch(`${commitUrl}${commitUrl.search}`, {method: 'POST'});
          const result = await res.json();
          showFlash(flashMessageEl, result.message);
        } catch(error) {
          showFlash(flashMessageEl, error.message);
        }
      }
    }).render(paypalBtnEl);  
  }

  function createURLWithParams(url, params={}) {
    const newURL = new URL(url);
    newURL.search = new URLSearchParams(params).toString();
    return newURL;
  }
}