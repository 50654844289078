export function clearCalls(el) {
  const delBtn = el.querySelector('.js-history-del-btn');
  const itemCheckboxes = el.querySelectorAll('.js-history-checkbox > input[type="checkbox"]');
  let isDisabled = delBtn.hasAttribute('disabled');

  itemCheckboxes.forEach(item => item.addEventListener('change', () => checkOnDisable()));

  const checkOnDisable = () => {
    const checkedCount = el.querySelectorAll('.js-history-checkbox > input[type="checkbox"]:checked').length;
    if (checkedCount > 0 && isDisabled) {
      delBtn.removeAttribute('disabled');
      isDisabled = false;
      return
    }
    if (checkedCount === 0 && !isDisabled) {
      delBtn.setAttribute('disabled', true);
      isDisabled = true;
    }
    return
  }
}