import { observer } from '../../helpers/observer';
import IvrOption from './ivr';
import IvrAction from './ivr-action';

export function IvrObserver(el) {
  const addNodeCallback = (addedNode) => {
    return new IvrOption(addedNode);
  }

  observer(el, addNodeCallback);
}

export function IvrActionObserver(el) {
  const addNodeCallback = (addedNode) => {
    if (addedNode instanceof HTMLLIElement) {
      return new IvrAction(addedNode);
    } 
    return
  }
  const delNodeCallback = (deletedNode) => {
    if (deletedNode instanceof HTMLLIElement) {
      const addActionBtn = document.querySelector('.js-ivr-add-action');
      if (addActionBtn.classList.contains('js-hide')) {
        addActionBtn.classList.remove('js-hide')
      }
    }
    return
  }

  observer(el, addNodeCallback, delNodeCallback);
}