export default class PendingNumber {
  constructor(el) {
    this.el = el;
    this.delBtn = this.el.querySelector('.js-del-pending');

    this.eventHandler();
  }

  eventHandler = () => {
    this.delBtn.addEventListener('ajax:success', () => this.el.remove());
  }
}